// Authentication actions
import {
  LOGIN_FAIL,
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
  SESSION_CLEAR_SESSION_DATA,
  UPDATE_ACCOUNT_SUCCESS,
} from "./types";
// General application actions
import {
  APP_SET_BACKEND_AVAILABILITY,
  /*APP_FETCH_START,
  APP_FETCH_FAILS,*/
  APP_SET_TRANSFER_PARAM,
  APP_UNSET_TRANSFER_PARAM,
  APP_SET_TOP_BAR_TITLE,
  APP_SET_MENU_ACTIVE,
} from "./types";
// Session application actions

// Persistance action
import {
  PERSIST_SET_ADDRESS_TYPES,
  PERSIST_SET_APPOINTMENT_TYPES,
  PERSIST_SET_ON_CALLS_TYPES,
  PERSIST_SET_PERSON_TITLES,
  PERSIST_SET_SEXES,
  PERSIST_SET_SHIFTS_TYPES,
  PERSIST_SET_VACATION_STATES,
  PERSIST_SET_CARS,
} from "./types";
// API URLS
import { NETWORK_ERROR_CODES, QUERIES } from "assets/staticData/enums";
// Helper functions
import { sendQuery } from "common/Helpers";
// Static data
import { LOCALES } from "assets/staticData/enums";
import { OFFLINE_USER } from "assets/staticData/combodata";
// Logging
import * as log from "loglevel";
const logger = log.getLogger("redux_actions");
logger.setLevel("debug");

/* LOGIN ACTIONS */
export const loginSuccess = (currentUser) => {
  return {
    type: LOGIN_SUCCESS,
    payload: currentUser,
  };
};

export const loginFaild = (error) => {
  return {
    type: LOGIN_FAIL,
    payload: error,
  };
};
/**
 * This Redux action handles the login request.
 * Send a login request with the received data to the backend and updates the state based on the response.
 *
 * @param {String} login - The login name of the user.
 * @param {String} password - The encrypted password.
 * @param {Boolean} backendAvailable Flag to state backendavailability. Defaults to true.
 */
export const login =
  (login, password, backendAvailable = true) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOGIN_PENDING });
      sendQuery(QUERIES.AUTHENTICATION, "post", {
        login,
        password,
        sort: "id,asc",
      }).then(
        (response) => {
          if (!response.hasOwnProperty("languageId")) {
            response.currentLocale = LOCALES.FRENCH;
          } else {
            if (response.languageId === LOCALES.GERMAN.languageId) {
              response.currentLocale = LOCALES.GERMAN;
            } else {
              response.currentLocale = LOCALES.FRENCH;
            }
          }
          dispatch({ type: LOGIN_SUCCESS, payload: response });
          dispatch({ type: APP_SET_BACKEND_AVAILABILITY, payload: true });
        },
        (error) => {
          logger.warn(error);
          if (error === NETWORK_ERROR_CODES.NO_SERVER_ERROR) {
            dispatch({ type: APP_SET_BACKEND_AVAILABILITY, payload: false });
          }
          if (backendAvailable) {
            dispatch({ type: LOGIN_FAIL, payload: error.message });
          } else {
            dispatch(startOfflineMode(LOCALES.FRENCH));
          }
        }
      );
    } catch (loginException) {
      logger.error(loginException);
      dispatch({ type: LOGIN_FAIL, payload: loginException });
    }
  };
/**
 * This action handles the logout process.
 * It clears the session data stored in the state, causing the application to return to the login screen.
 */
export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: SESSION_CLEAR_SESSION_DATA });
    dispatch({ type: LOGOUT_SUCCESS });
  } catch (logoutException) {
    dispatch({ type: LOGOUT_FAIL });
  }
};

export const forceLogout = () => async (dispatch) => {
  dispatch({ type: LOGOUT_SUCCESS });
};
/**
 * This action handles switching to the offline mode.
 * It updates the state session data to a default offline user with limited access rights. (TODO)
 */
export const startOfflineMode = (locale = LOCALES.GERMAN) => {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      ...OFFLINE_USER,
      currentLocale: locale,
    },
  };
};
/**
 * This action is called when the account data of the currently logged in user was altered in the frontend.
 * It fetches the current data of the logged in user and updates the state on success, stores the received error in the state else.
 *
 * @param {Object} accountData
 */
export const updateAccount = (accountData) => {
  return { type: UPDATE_ACCOUNT_SUCCESS, payload: accountData };
};

/* GENERAL APPLICATION ACTIONS */
/**
 * This action gets called on login.
 * It fetches static data (i.e. Combobox-Content) from the backend.
 * Stores the received values in the state on success, generates a log entry else.
 *
 * @param {String} token
 */
export const fetchOfflineData = (token) => async (dispatch) => {
  // TODO FETCH OFFLINE DATA
};

/**
 * This action is used to transfer parameters between two views.
 * Generally used to initialize a view when a route is changed programatically.
 *
 * @param {Number} params
 */
export const setTransferParams = (params) => {
  return {
    type: APP_SET_TRANSFER_PARAM,
    payload: params,
  };
};

/**
 * This action clears the transfer parameter.
 * Use this after the transfer parameter was used.
 */
export const unsetTransferParams = () => {
  return {
    type: APP_UNSET_TRANSFER_PARAM,
  };
};
/**
 * This action sets the title label of the top bar.
 *
 * @param {String} title The message key of the title that's to be displayed.
 */
export const setTopbarTitle = (title, appendix = null) => {
  document.title = title;
  return {
    type: APP_SET_TOP_BAR_TITLE,
    payload: [title, appendix],
  };
};

export const toggleOfflineMode = (value) => {
  return {
    type: APP_SET_BACKEND_AVAILABILITY,
    payload: value,
  };
};
/**
 * This action updates whether or nor the sidebar menu is active.
 *
 * @param {boolean} active True if the menu is active, false else.
 */
export const setMenuActive = (active) => {
  return {
    type: APP_SET_MENU_ACTIVE,
    payload: active,
  };
};
/* PERSISTANCE ACTIONS */
/** The following actions update persistant BE data into Redux. */
export const setOnCallTypes = (payload) => {
  return {
    type: PERSIST_SET_ON_CALLS_TYPES,
    payload,
  };
};
export const setShiftTypes = (payload) => {
  return {
    type: PERSIST_SET_SHIFTS_TYPES,
    payload,
  };
};
export const setAppointmentTypes = (payload) => {
  return {
    type: PERSIST_SET_APPOINTMENT_TYPES,
    payload,
  };
};
export const setTitles = (payload) => {
  return {
    type: PERSIST_SET_PERSON_TITLES,
    payload,
  };
};
export const setSexes = (payload) => {
  return {
    type: PERSIST_SET_SEXES,
    payload,
  };
};
export const setAddressTypes = (payload) => {
  return {
    type: PERSIST_SET_ADDRESS_TYPES,
    payload,
  };
};

export const setVacationStates = (payload) => {
  return {
    type: PERSIST_SET_VACATION_STATES,
    payload,
  };
};

export const setCars = (payload) => {
  return {
    type: PERSIST_SET_CARS,
    payload,
  };
};
