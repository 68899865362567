import React from "react";
// Responsive
import { isDesktop } from "react-device-detect";
// Redux
import { connect } from "react-redux";
import { setTopbarTitle } from "actions";
import { setChangePending, setBillSession } from "actions/sessionActions";
// Custom components
import {
  BillFilterLayout,
  BillEditView,
  MultiPaymentsDialog,
  MultiStatusDialog,
  ToolButton,
} from "./components";
import BillFilterTable from "./components/BillFilterTable";
// Helper functions
import {
  generateQueryParameters,
  sendQuery,
  initLogger,
  changePendingConfirm,
} from "common/Helpers";
// PrimeReact components
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";
// Localization
import { injectIntl } from "react-intl";
// Static values
import {
  MESSAGE_KEYS,
  QUERIES,
  MESSAGE_SEVERITY,
  EMPTY_FILTER_QUERY,
  ROWS_PER_PAGE,
} from "assets/staticData/enums";
// Styling
import "./Style.scss";

// Logging
const logger = initLogger("bills_view");

class BillsView extends React.Component {
  state = {
    first: 0,
    rows: isDesktop ? 10 : 5,
    rowsPerPage: ROWS_PER_PAGE,
    displayedBills: [],
    totalBills: 0,
    currentPage: 0,
    selectedBill: null,
    fetchPending: false,
    fetchError: null,
    billFetchPending: false,
    billFetchError: null,
    queryString: EMPTY_FILTER_QUERY,
    selectedRow: null,

    skipFetch: false,
    selectedBills: [],

    selectionPaymentPending: false,
    selectionBillsPending: false,
    selectionReminderPending: false,
    allReminderPending: false,
    selectionStatusPending: false,

    totalPaid: 0,
    totalAmount: 0,

    multiDialogVisible: false,
    multiStatusDialogVisible: false,
  };

  /**
   * Fetches bill data on component mount.
   * If the transfer id is set, load the bill corresponding to the transfer id and clear the ID from the redux store.
   */
  componentDidMount = () => {
    const { currentPage, rows } = this.state;
    const { transferId, setTopbarTitle, setChangePending, billSession } =
      this.props;
    setTopbarTitle(
      this.props.intl.formatMessage({ id: MESSAGE_KEYS.MENU_BILLS })
    );
    setChangePending(false);
    if (billSession) {
      // Load stored session, init edit layout with transfer data if set, use session data else.
      let selectedBill = transferId
        ? typeof transferId === "number"
          ? transferId
          : transferId.transactionId
        : billSession.selectedSessionBill
        ? billSession.selectedSessionBill.transactionId
        : null;
      this.setState({
        ...billSession,
        selectedBill,
      });
    } else {
      // No session data set, start default query. Load transfer data if set.
      this.filterBills(currentPage, rows);
      if (transferId) {
        this.handleSelectionChange(transferId);
      }
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { rows, displayedBills, currentPage, selectedBill, queryString } =
      this.state;

    if (
      prevState.currentPage !== currentPage ||
      prevState.rows !== rows ||
      prevState.queryString !== queryString ||
      prevState.selectedBill !== selectedBill
    ) {
      this.handleSessionUpdate(displayedBills);
    }
  };

  handleSessionUpdate = (displayedBills = []) => {
    const { setBillSession, billSession } = this.props;

    const {
      first,
      rows,
      totalBills,
      currentPage,
      queryString,
      selectedRow,
      totalAmount,
      totalPaid,
    } = this.state;
    setBillSession({
      ...billSession,
      first,
      rows,
      displayedBills: [...displayedBills],
      totalBills,
      currentPage,
      queryString,
      selectedRow,
      totalAmount,
      totalPaid,
    });
  };

  handleSearch = (filter) => {
    const { rows } = this.state;
    let queryString = generateQueryParameters(
      filter,
      "yearsort,invnumbersort"
    );
    this.setState(
      {
        first: 0,
        currentPage: 0,
        queryString,
        fetchPending: true,
        fetchError: null,
        selectedBills: [],
      },
      this.filterBills(0, rows, queryString)
    );
  };

  filterBills = (page, rows, queryString) => {
    try {
      this.setState({ fetchPending: true }, () => {
        sendQuery(
          `${QUERIES.GET_BILLS_PAGES}page=${
            page ? page : this.state.currentPage
          }&size=${rows ? rows : this.state.rows}${
            queryString ? queryString : this.state.queryString
          }`,
          "get"
        ).then(
          (response) => {
            const { content, totalAmount1, totalAmount2, totalElements } =
              response;
            this.setState(
              {
                displayedBills: content,
                totalBills: totalElements,
                fetchPending: false,
                currentPage: page,
                totalAmount: totalAmount1,
                totalPaid: totalAmount1 - totalAmount2,
                rows,
              },
              () => {
                this.handleSessionUpdate([...response.content]);
              }
            );
          },
          (error) => {
            this.setState(
              {
                fetchPending: false,
                fetchError: error,
              },
              () => {
                logger.error(error, error.message);
                this.toast.show({
                  severity: MESSAGE_SEVERITY.ERROR,
                  summary:
                    typeof error === "string"
                      ? error
                      : this.props.intl.formatMessage({
                          id: MESSAGE_KEYS.ERROR_DATA_FETCH,
                        }),
                });
                this.handleSessionUpdate();
              }
            );
          }
        );
      });
    } catch (filterException) {
      logger.error(filterException);
      this.handleSessionUpdate();
    }
  };

  handleMultiStatusClick = () => {
    const { selectedBills } = this.state;
    if (selectedBills.length > 0) {
      this.setState({
        multiStatusDialogVisible: true,
        selectionStatusPending: true,
      });
    }
  };

  handleMultiPaymentClick = () => {
    const { selectedBills } = this.state;
    if (selectedBills.length > 0) {
      this.setState({
        multiDialogVisible: true,
        selectionPaymentPending: true,
      });
    }
  };

  /**
   * Gets called when a row in the table is selected.
   * If the changePending value within the state is true, the user will be prompted to confirm the selection.
   * On confirmation, the state if updated and the data for the new selection will be fetched and displayed. No change will happen else.
   *
   * @param {Object} selection - The id of the new selection.
   */
  handleSelectionChange = (selection) => {
    const { changePending, intl } = this.props;
    if (this.state.skipFetch) {
      this.setState({ skipFetch: false });
    } else {
      if (!changePending) {
        this.changeSelection(selection);
      } else {
        changePendingConfirm(selection, this.changeSelection, intl);
      }
    }
  };

  changeSelection = (selection) => {
    // Set selected bill to null in order to allow data refresh when clicking the same entry.
    this.setState(
      {
        selectedBill: null,
      },
      () => {
        this.setState(
          { selectedBill: selection.transactionId, selectedRow: selection },
          () => {
            this.props.setChangePending(false);
          }
        );
      }
    );
  };

  handleAllCheckboxSelection = (checked) => {
    const { selectedBills, displayedBills } = this.state;
    this.setState({ skipFetch: true });
    let newSelection = [];
    if (checked) {
      newSelection = [...selectedBills];
      displayedBills.forEach((bill) => {
        if (
          newSelection.findIndex((selection) => {
            return selection.transactionId === bill.transactionId;
          }) === -1
        ) {
          newSelection.push(bill);
        }
      });
    } else {
      let deleteIds = [];
      displayedBills.forEach((bill) => {
        deleteIds.push(bill.transactionId);
      });
      newSelection = [
        ...selectedBills.filter((bill) => {
          return !deleteIds.includes(bill.transactionId);
        }),
      ];
    }
    this.setState({
      selectedBills: [...newSelection],
    });
  };

  handleCheckboxSelection = (rowData, checked) => {
    const { selectedBills } = this.state;
    this.setState({ skipFetch: true }, () => {
      if (checked) {
        this.setState({
          selectedBills: [...selectedBills, { ...rowData }],
        });
      } else {
        this.setState({
          selectedBills: [
            ...selectedBills.filter((bill) => {
              return bill.transactionId !== rowData.transactionId;
            }),
          ],
        });
      }
    });
  };

  /**
   * Renders the filter component.
   *
   * @returns {JSX.Element} - The rendered filter.
   */
  renderFilter = () => {
    const { intl } = this.props;
    const { ERROR_RENDER, BILLS_FILTER_TITLE_LABEL } = MESSAGE_KEYS;
    const { fetchPending } = this.state;
    try {
      return (
        <Panel
          key="bills_filter"
          header={intl.formatMessage({ id: BILLS_FILTER_TITLE_LABEL })}
          toggleable
          collapsed={true}
        >
          <BillFilterLayout
            isPending={fetchPending}
            handleSearch={this.handleSearch}
          />
        </Panel>
      );
    } catch (renderException) {
      logger.error(renderException);
      return <div>{this.props.intl.formatMessage({ id: ERROR_RENDER })}</div>;
    }
  };

  renderContent = () => {
    const {
      selectedBill,
      displayedBills,
      selectedBills,
      fetchError,
      fetchPending,
      queryString,
      totalBills,
      totalAmount,
      totalPaid,
    } = this.state;
    return (
      <div className="grid">
        <div
          className={`col-${isDesktop ? "6" : "12"}`}
          key="bills_filter_left"
        >
          <div className="flex tool_row">
            <ToolButton
              displayedBills={displayedBills}
              selectedBills={selectedBills}
              disabled={fetchError || fetchPending}
              handleMultiPaymentClick={this.handleMultiPaymentClick}
              handleMultiStatusClick={this.handleMultiStatusClick}
              queryString={queryString}
              handleNewClick={() => {
                this.handleSelectionChange({
                  transactionId: new Date().getTime() * -1,
                });
              }}
            />
            {this.renderFilter()}
          </div>
          <BillFilterTable
            displayedBills={displayedBills}
            selectedRow={this.state.selectedRow}
            fetchPending={fetchPending}
            handleSelectionChange={this.handleSelectionChange}
            filterBills={this.filterBills}
            selectedBills={selectedBills}
            handleCheckboxSelection={this.handleCheckboxSelection}
            handleAllCheckboxSelection={this.handleAllCheckboxSelection}
            totalBills={totalBills}
            totalAmount={totalAmount}
            totalPaid={totalPaid}
          />
        </div>
        <div
          className={`col-${isDesktop ? "6" : "12"}`}
          key="bills_filter_right"
        >
          <BillEditView
            key="bill_edit_view"
            selectedBill={selectedBill}
            currentUser={this.props.currentUser}
            handleParentUpdate={(newId) => {
              this.filterBills();
              this.setState({ selectedBill: newId });
            }}
            handleCustomerSelection={(personId) => {
              this.handleSearch({ transactionCustomer: personId });
            }}
          />
        </div>
      </div>
    );
  };

  render = () => {
    const { multiDialogVisible, selectedBills, multiStatusDialogVisible } =
      this.state;
    return (
      <div>
        <Toast ref={(el) => (this.toast = el)} />
        <MultiPaymentsDialog
          onHide={() =>
            this.setState({
              multiDialogVisible: false,
              selectionPaymentPending: false,
            })
          }
          visible={multiDialogVisible}
          value={selectedBills}
          handleParentUpdate={() => {
            this.setState(
              {
                selectedBills: [],
                selectionPaymentPending: false,
              },
              () => {
                this.filterBills();
              }
            );
            this.toast.show({
              severity: MESSAGE_SEVERITY.SUCCESS,
              summary: this.props.intl.formatMessage({
                id: MESSAGE_KEYS.PAYMENT_SAVE_SUCCESS_MESSAGE,
              }),
            });
          }}
        />
        <MultiStatusDialog
          onHide={() =>
            this.setState({
              multiStatusDialogVisible: false,
              selectionStatusPending: false,
            })
          }
          visible={multiStatusDialogVisible}
          value={selectedBills}
          handleParentUpdate={() => {
            this.setState(
              {
                selectedBills: [],
                selectionStatusPending: false,
              },
              () => {
                this.filterBills();
              }
            );
            this.toast.show({
              severity: MESSAGE_SEVERITY.SUCCESS,
              summary: this.props.intl.formatMessage({
                id: MESSAGE_KEYS.PAYMENT_SAVE_SUCCESS_MESSAGE,
              }),
            });
          }}
        />
        {this.renderContent()}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      application: { routerParams = null },
      authentication: { currentUser = null },
      session: { changePending = false, billSession = null },
    } = state;
    return {
      transferId: routerParams,
      currentUser,
      changePending,
      billSession,
    };
  } catch (mapException) {
    return {
      transferId: null,
      currentUser: null,
      changePending: false,
      billSession: null,
    };
  }
};

export default connect(mapStateToProps, {
  setTopbarTitle,
  setChangePending,
  setBillSession,
})(injectIntl(BillsView));
