// Login actions
export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const START_OFFLINE_MODE = "START_OFFLINE_MODE";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const UPDATE_ACCOUNT_PENDING = "UPDATE_ACCOUNT_PENDING";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_FAIL = "UPDATE_ACCOUNT_FAIL";
export const SET_TOKEN_EXPIRED = "SET_TOKEN_EXPIRED";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
// Application actions
export const APP_SET_BACKEND_AVAILABILITY = "APP_SET_BACKEND_AVAILABILITY";
export const APP_FETCH_FAILS = "APP_FETCH_FAILS";
export const APP_FETCH_START = "APP_FETCH_START";
export const APP_FETCH_SUCCESS = "APP_FETCH_SUCCESS";
export const APP_FETCH_APPOINTMENTS = "APP_FETCH_APPOINTMENTS";
export const APP_SET_TRANSFER_PARAM = "APP_SET_TRANSFER_PARAM";
export const APP_UNSET_TRANSFER_PARAM = "APP_UNSET_TRANSFER_PARAM";
export const APP_SET_TOP_BAR_TITLE = "APP_SET_TOP_BAR_TITLE";
export const APP_SET_MENU_ACTIVE = "APP_SET_MENU_ACTIVE";
// Session actions
export const SESSION_SET_CHANGE_PENDING = "SESSION_SET_CHANGE_PENDING";
export const SESSION_SET_ADDRESS_SESSION = "SESSION_SET_ADDRESS_SESSION";
export const SESSION_SET_PAYMENT_SESSION = "SESSION_SET_PAYMENT_SESSION";
export const SESSION_SET_CUSTOMER_SESSION = "SESSION_SET_CUSTOMER_SESSION";
export const SESSION_SET_BILL_SESSION = "SESSION_SET_BILL_SESSION";
export const SESSION_SET_APPOINTMENT_SESSION =
  "SESSION_SET_APPOINTMENT_SESSION";
export const SESSION_SET_DRIVES_SESSION = "SESSION_SET_DRIVES_SESSION";
export const SESSION_CLEAR_SESSION_DATA = "SESSION_CLEAR_SESSION_DATA";
// Persistent data actions
export const PERSIST_SET_ON_CALLS_TYPES = "PERSIST_SET_ON_CALLS_TYPES";
export const PERSIST_SET_SHIFTS_TYPES = "PERSIST_SET_SHIFTS_TYPES";
export const PERSIST_SET_APPOINTMENT_TYPES = "PERSIST_SET_APPOINTMENT_TYPES";
export const PERSIST_SET_PERSON_TITLES = "PERSIST_SET_PERSON_TITLES";
export const PERSIST_SET_SEXES = "PERSIST_SET_SEXES";
export const PERSIST_SET_ADDRESS_TYPES = "PERSIST_SET_ADDRESS_TYPES";
export const PERSIST_SET_VACATION_STATES = "PERSIST_SET_VACATION_STATES";
export const PERSIST_SET_CARS = "PERSIST_SET_CARS";
// Appointments wizard actions
export const WIZARD_RESET = "WIZARD_RESET";
export const WIZARD_INIT = "WIZARD_INIT";
export const WIZARD_SET_VALUE = "WIZARD_SET_VALUE";
export const WIZARD_UPDATE_FROM_ADDRESS = "WIZARD_UPDATE_FROM_ADDRESS";
export const WIZARD_UPDATE_TO_ADDRESS = "WIZARD_UPDATE_TO_ADDRESS";
export const WIZARD_UPDATE_DRIVE = "WIZARD_UPDATE_DRIVE";
// Mobile view actions
export const MOBILE_SET_DAY = "MOBILE_SET_DAY";
export const MOBILE_SET_USER = "MOBILE_SET_USER";
export const MOBILE_SET_ALL_DAYS = "MOBILE_SET_ALL_DAYS";
