import React from "react";

//primeng components
import { Dialog } from "primereact/dialog";
import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";

// Styling
import "./Style.scss";
import { MESSAGE_KEYS } from "assets/staticData/enums";
import { GENDERS, TITLES, TRANSPORT_TYPES } from "assets/staticData/combodata";
// Localization
import { useIntl } from "react-intl";
import { dateToString, initLogger } from "common/Helpers";
// Logging
const logger = initLogger("appointment_history_dialog");

const AppointmentHistoryDialog = ({
  visible,
  hideHistoryModal,
  title,
  content,
  isGerman,
  status,
}) => {
  const intl = useIntl();

  const getFormattedDate = (date) => {
    if (date) {
      const splittedDate = date.split("T");
      let timePart = splittedDate[1].split(".")[0].slice(0, 5);

      // Adjust time if daylight saving time
      if (isDaylightSavingTime(new Date(date))) {
        const [hours, minutes] = timePart.split(":").map(Number);
        const adjustedHours = hours + 1;
        timePart = `${adjustedHours.toString().padStart(2, "0")}:${minutes}`;
      }

      return dateToString(splittedDate[0], "/") + " - " + timePart;
    } else {
      return "-";
    }
  };

  const isDaylightSavingTime = (date) => {
    // If the offset changes between January and July, it's daylight saving time
    const januaryOffset = new Date(
      date.getFullYear(),
      0,
      1
    ).getTimezoneOffset();
    const julyOffset = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.abs(januaryOffset - julyOffset) > 0;
  };

  const generateHistoricalModalContent = (histories) => {
    if (!histories) {
      return;
    }
    const events = [];
    histories.forEach((history) => {
      events.push({
        status: history?.driverName,
        changes: history?.changes,
        date: getFormattedDate(history?.lastModifyDate),
        icon: "pi pi-pencil",
        color: "#003d69",
      });
    });

    return (
      <Timeline
        value={events}
        align="alternate"
        className="customized-timeline"
        marker={customizedMarker}
        content={customizedContent}
      />
    );
  };

  const customizedMarker = (item) => {
    return (
      <span
        className="custom-marker"
        style={{ backgroundColor: item.color, color: "white" }}
      >
        <i className={item.icon}></i>
      </span>
    );
  };

  const shouldBeHidden = (oldValue, newValue) => {
    const trimmedOldValue = oldValue.trim();
    const trimmedNewValue = newValue.trim();

    return (
      (trimmedOldValue === '""' ||
        trimmedOldValue === "null" ||
        trimmedOldValue === "false" ||
        trimmedOldValue === '"0"') &&
      (trimmedNewValue === "" ||
        trimmedNewValue === "null" ||
        newValue === ' ""')
    );
  };

  const formatHistoryStringDate = (stringDate, onlyTime = false) => {
    if (stringDate.includes("null")) {
      return stringDate;
    }

    return !onlyTime
      ? stringDate
          .trim()
          .replace(/"/g, "")
          .replace(/T/g, " ")
          .replace(/Z/g, " ")
      : stringDate
          .trim()
          .replace(/"/g, "")
          .replace(/T/g, " ")
          .replace(/Z/g, " ")
          .split(" ")[1]
          .substring(0, 5);
  };

  const replaceLabelsInHistory = (changes) => {
    let finalChangesString = "";

    //backend struktur: prefix.propertyName : oldValue => newValue <br>
    const splittedChanges = changes.split("<br>");

    splittedChanges.forEach((change) => {
      const DATE_PROPERTIES = [
        "appointment.meetingTime",
        "appointment.starttime",
        "appointment.endtime",
      ];

      if (!change) {
        return;
      }

      if (change.includes(".customer")) {
        change += " &#128100;";
      }
      change = change.replace(/.customer/g, "");

      let propertyName = change.split("&#58;")[0].trim();
      let oldValue = change.split("&#58;")[1].split("=>")[0];
      let newValue = change.split("&#58;")[1].split("=>")[1];

      if (shouldBeHidden(oldValue, newValue)) {
        //for unnecessary changes like empty string to null
        return;
      }

      if (DATE_PROPERTIES.includes(propertyName)) {
        const convertedOld = formatHistoryStringDate(
          oldValue,
          propertyName.includes("starttime") ? false : true
        );
        const regexOld = new RegExp(oldValue, "g");
        change = change.replace(regexOld, " " + convertedOld);

        const convertedNew = formatHistoryStringDate(
          newValue,
          propertyName.includes("starttime") ? false : true
        );
        const regex = new RegExp(newValue, "g");
        change = change.replace(regex, " " + convertedNew);
      }

      //replace id thru name
      if (propertyName === "appointment.state") {
        const oldstateName = getNameByTypeAndId("state", oldValue, isGerman);
        const newStateName = getNameByTypeAndId("state", newValue, isGerman);
        if (oldstateName !== "ERROR-INVALID_TYPE") {
          const regex = new RegExp(oldValue, "g");
          change = change.replace(regex, " " + oldstateName);
        }
        if (newStateName !== "ERROR-INVALID_TYPE") {
          const regex = new RegExp(newValue, "g");
          change = change.replace(regex, " " + newStateName);
        }
      } else if (propertyName === "appointment.sexId") {
        const oldstateName = getNameByTypeAndId("sexId", oldValue, isGerman);
        const newStateName = getNameByTypeAndId("sexId", newValue, isGerman);

        if (
          oldstateName !== "ERROR-INVALID_TYPE" &&
          oldstateName.includes(".")
        ) {
          const regex = new RegExp(oldValue, "g");
          change = change.replace(
            regex,
            " " +
              intl.formatMessage({
                id: oldstateName,
              })
          );
        }
        if (
          newStateName !== "ERROR-INVALID_TYPE" &&
          newStateName.includes(".")
        ) {
          const regex = new RegExp(newValue, "g");
          change = change.replace(
            regex,
            " " +
              intl.formatMessage({
                id: newStateName,
              })
          );
        }
      } else if (propertyName === "appointment.titleId") {
        const oldstateName = getNameByTypeAndId("titleId", oldValue, isGerman);
        const newStateName = getNameByTypeAndId("titleId", newValue, isGerman);

        if (
          oldstateName !== "ERROR-INVALID_TYPE" &&
          oldstateName.includes(".")
        ) {
          const regex = new RegExp(oldValue, "g");
          change = change.replace(
            regex,
            " " +
              intl.formatMessage({
                id: oldstateName,
              })
          );
        }
        if (newStateName !== "ERROR-INVALID_TYPE") {
          const regex = new RegExp(newValue, "g");
          change = change.replace(
            regex,
            " " +
              intl.formatMessage({
                id: newStateName,
              })
          );
        }
      } else if (propertyName === "appointment.transportTypeInvoice") {
        const oldstateName = getNameByTypeAndId(
          "transportType",
          oldValue,
          isGerman
        );
        const newStateName = getNameByTypeAndId(
          "transportType",
          newValue,
          isGerman
        );

        if (
          oldstateName !== "ERROR-INVALID_TYPE" &&
          oldstateName.includes(".")
        ) {
          const regex = new RegExp(oldValue, "g");
          change = change.replace(
            regex,
            " " +
              intl.formatMessage({
                id: oldstateName,
              })
          );
        }
        if (
          newStateName !== "ERROR-INVALID_TYPE" &&
          newStateName.includes(".")
        ) {
          const regex = new RegExp(newValue, "g");
          change = change.replace(
            regex,
            " " +
              intl.formatMessage({
                id: newStateName,
              })
          );
        }
      }

      if (propertyName.includes(".check")) {
        // checkbox translation, it already has the translation in the propertyName
        let checkbox = change
          .split(".check")[0]
          .split(":")
          [isGerman ? 0 : 1].replace(/"/g, "")
          .split(".")[1];
        change = checkbox + ": " + oldValue + " => " + newValue;
      } else {
        //propertyName translation using intl
        const translation = translateProperty(propertyName);
        if (translation !== "ERROR-NOTFOUND") {
          const regex = new RegExp(propertyName, "g");
          change = change.replace(
            regex,
            intl.formatMessage({
              id: translation,
            })
          );
        }
      }

      change = change.replace(/false/g, "<span class='red'> &#215;</span>"); // x
      change = change.replace(/null/g, "<span class='red'> &#215;</span>"); // x
      change = change.replace(/true/g, "<span class='green'> &#10003; </span>"); // ✓
      change = change.replace(/=>/g, "<span> &#8594; </span>"); // →

      finalChangesString += change + "<br>";
    });

    return finalChangesString;
  };

  const getNameByTypeAndId = (type, id, isGerman) => {
    if (id.includes("null") || type == null) {
      return id;
    }
    const translation = isGerman ? "nameDe" : "nameFr";
    id = parseInt(id);
    switch (type) {
      case "state":
        return status.find((status) => status.appointmentStateId === id)[
          translation
        ];
      case "sexId":
        return GENDERS.find((title) => title.sexId === id)["messageKey"];
      case "titleId":
        return TITLES.find((title) => title.titleId === id)["messageKey"];
      case "transportType":
        return TRANSPORT_TYPES.find((title) => title.transportTypeId === id)[
          "messageKey"
        ];
      default:
        return "ERROR-INVALID_TYPE";
    }
  };

  const translateProperty = (string) => {
    const searchString = string.toLowerCase();

    for (const key in MESSAGE_KEYS) {
      if (MESSAGE_KEYS[key].toLowerCase().includes(searchString)) {
        return MESSAGE_KEYS[key];
      }
    }

    logger.error("TRANSLATION NOT FOUND " + searchString);
    return "ERROR-NOTFOUND";
  };

  const customizedContent = (item) => {
    return (
      <Card title={item.status} subTitle={item.date}>
        {
          <div
            dangerouslySetInnerHTML={{
              __html: replaceLabelsInHistory(item.changes),
            }}
            className="generated-history-content"
          />
        }
      </Card>
    );
  };

  return (
    <Dialog
      header={title}
      visible={visible}
      style={{ width: "50vw" }}
      onHide={() => hideHistoryModal()}
      closeOnEscape={true}
      modal
      blockScroll={true}
      className="history_dialog"
    >
      {generateHistoricalModalContent(content)}
    </Dialog>
  );
};

export default AppointmentHistoryDialog;
