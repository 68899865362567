import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// PrimeReact Components
import { ProgressBar } from "primereact/progressbar";
// Views
import SettingsView from "components/SettingsView";
import AccountView from "components/AccountView";
import DashboardView from "components/DashboardView";
import UsersView from "components/UsersView";
import CustomersView from "components/CustomersView";
import AddressView from "components/AddressView";
import SchedulerView from "components/SchedulerView";
import BillsView from "components/BillsView";
import PaymentsView from "components/PaymentsView";
import VacationsView from "components/VacationsView";
import ShiftsView from "components/ShiftsView";
import ProductsView from "components/ProductsView";
import VehiclesView from "components/VehiclesView";
import OnCallsView from "components/OnCallsView";
import EmptyView from "components/EmptyPage";
import RegularsView from "components/RegularsView";
import RegularListView from "components/RegularListView";
import HolidayView from "components/HolidayView";
import DriverStatusView from "components/DriverStatusView";
import AppointmentSearchView from "components/AppointmentSearchView";
import MonthReportView from "components/MonthReportView/index";
// Custom components
import { ErrorBoundary, ProtectedRoute } from "components/common";
// Static data
import { URLS } from "assets/staticData/enums";
import DrivesView from "components/DrivesView/index";
import ShoutboxView from "components/ShoutboxView/index";

const AppRoutes = ({
  isAdmin,
  isBilling,
  isFidus,
  backendAvailable,
  isPlanning,
}) => {
  const {
    ACCOUNT,
    USERS,
    SCHEDULER,
    ADDRESSES,
    BILLS,
    CUSTOMERS,
    PAYMENTS,
    VACATIONS,
    SHIFTS,
    SETTINGS,
    PRODUCTS,
    VEHICLES,
    ON_CALL,
    DASHBOARD,
    REGULARS,
    REGULARS_LIST,
    HOLIDAYS,
    DRIVES,
    DRIVER_STATUS,
    APPOINTMENTS_SEARCH,
    SHOUTBOX,
    MONTH_REPORT,
  } = URLS;
  return (
    <ErrorBoundary>
      <Suspense fallback={<ProgressBar mode="indeterminate" />}>
        <div className="layout-main">
          <Switch>
            <ProtectedRoute
              path={VACATIONS}
              component={VacationsView}
              exact
              disabled={!backendAvailable || isFidus}
            />
            <ProtectedRoute
              path={ON_CALL}
              component={OnCallsView}
              exact
              disabled={!backendAvailable || isFidus}
            />

            <ProtectedRoute
              path={ACCOUNT}
              component={AccountView}
              exact
              disabled={!backendAvailable || isFidus}
            />

            <ProtectedRoute
              path={PAYMENTS}
              component={PaymentsView}
              disabled={!(isAdmin || isBilling) || isFidus}
              exact
            />
            <ProtectedRoute
              path={PRODUCTS}
              component={ProductsView}
              disabled={!(isAdmin || isBilling) || isFidus}
              exact
            />
            <ProtectedRoute
              path={CUSTOMERS}
              component={CustomersView}
              disabled={!(isAdmin || isBilling) || isFidus}
              exact
            />
            <ProtectedRoute
              path={ADDRESSES}
              component={AddressView}
              disabled={!(isAdmin || isBilling) || isFidus}
              exact
            />
            <ProtectedRoute
              path={BILLS}
              component={BillsView}
              disabled={!(isAdmin || isBilling) || isFidus}
              exact
            />
            <ProtectedRoute
              path={DASHBOARD}
              exact
              component={DashboardView}
              disabled={!(isAdmin || isFidus)}
            />

            <ProtectedRoute
              path={SHIFTS}
              component={ShiftsView}
              disabled={!(isAdmin || isPlanning || isBilling) || isFidus}
              exact
            />
            <ProtectedRoute
              path={SCHEDULER}
              component={SchedulerView}
              disabled={!(isAdmin || isPlanning) || isFidus}
              exact
            />
            <ProtectedRoute
              path={USERS}
              component={UsersView}
              disabled={!isAdmin}
            />
            <ProtectedRoute
              path={VEHICLES}
              component={VehiclesView}
              disabled={!isAdmin}
              exact
            />
            <ProtectedRoute
              path={SETTINGS}
              component={SettingsView}
              disabled={!isAdmin}
              exact
            />
            <ProtectedRoute
              path={REGULARS}
              disabled={!isAdmin}
              component={RegularsView}
              exact
            />
            <ProtectedRoute
              path={REGULARS}
              disabled={!isAdmin}
              component={RegularsView}
              exact
            />
            <ProtectedRoute
              path={REGULARS_LIST}
              component={RegularListView}
              disabled={isFidus}
              exact
            />
            <ProtectedRoute
              path={HOLIDAYS}
              component={HolidayView}
              disabled={isFidus}
              exact
            />
            <ProtectedRoute
              path={DRIVES}
              component={DrivesView}
              disabled={isFidus}
              exact
            />
            <ProtectedRoute
              path={DRIVER_STATUS}
              component={DriverStatusView}
              disabled={isFidus}
              exact
            />
            <ProtectedRoute
              path={APPOINTMENTS_SEARCH}
              component={AppointmentSearchView}
              disabled={isFidus}
              exact
            />
            <ProtectedRoute
              path={SHOUTBOX}
              component={ShoutboxView}
              disabled={isFidus}
              exact
            />
            <ProtectedRoute
              path={MONTH_REPORT}
              component={MonthReportView}
              disabled={isFidus}
              exact
            />
            <Route path="/notfound" component={EmptyView} />
            <Redirect to="/notfound" />
          </Switch>
        </div>
      </Suspense>
    </ErrorBoundary>
  );
};

export default AppRoutes;
